import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Card from '../components/card/card'
import Seo from "../components/seo"

import { filters, projects } from "../components/data/projects";

const ProjectsPage = () => {

    const [ selection, setSelection ] = useState([]);
    const [ projectList, setProjectList ] = useState(Object.keys(projects));

    const filterProjects = (selection) => {
        // console.log(selection);
        if(selection.length === 0) {
            setProjectList(Object.keys(projects));
        } else {
            let filteredProjects = Object.keys(projects).filter((key) => {
                return selection.filter(x => projects[key].filter.indexOf(x) !== -1).length > 0 ? true : false;                
            });
            setProjectList(filteredProjects);
        }
    }

    const toggleSelection = ( selected ) => {
        if(selection.indexOf(selected) > -1){
            let newSelection = selection.filter((s) => s!==selected);
            setSelection(newSelection);
            filterProjects(newSelection);
        } else {
            setSelection([...selection, selected]);
            filterProjects([...selection, selected]);
        }
    }

    return (
        <>
            <Seo title="Projects" />
            <section className={`projects-section fix-header`}>
             <div className={`content`}>
               <h6 className={`primary center`}>Recents from the workshop</h6>
               <h1 className={`center`}>Projects Showcase</h1>
                <div className={`content--md-center add-margin-bottom`}>
                 <p className={`lg primary center`}>A pretentious collection of selected work. To set the tone, I love building anything that's creative and impactful with a potential to elevate user experience.</p>
               </div>
                <div className={`filter-buttons`}>
                    {filters.map((filter)=>(
                        <div className={`filter ${selection.indexOf(filter) > -1 ? 'active' : null}`} onClick={()=>{toggleSelection(filter)}} key={filter}>{filter} <div className={`cross`}></div></div>
                    ))}
                </div>
             </div>
            </section>
            <section className={`fix-bottom`}>
             <div className={`content`}>
                <div className={`projects-wrapper`}>
                <div className={`cards-wrapper`}>
                    {projectList.map((projectName) => (
                      <Card
                        imageSelector={projects[projectName].imageSelector}
                        title={projects[projectName].title}
                        description={projects[projectName].description}
                        categories={projects[projectName].categories.join(', ')}
                        link={projects[projectName].link}
                        date={projects[projectName].date}
                        themeColor={projects[projectName].themeColor}
                        key={projectName}
                      />
                    ))}
                </div>
             </div>
            </div>
         </section>
        </>
    )
}

export default ProjectsPage
